import {
  ADD_PAGE_TO_SKIP_LIST,
  REMOVE_PAGE_FROM_SKIP_LIST,
  UPDATE_HOME_STATE,
  UPDATE_NESTED_HOME_STATE,
} from './Home.type';

const initialState = {
  currentPage: 0,
  vehicleCount: 1,
  currentVehicle: 1,
  skipPages: [],
  vehiclesData: {},
  homeOwnerState: {},
  homeState: {
    propertyType: 'primary_residence',
  },
  coreg: {
    atFaultAccident: false,
    notAtFaultAccident: false,
    DUI: false,
    claim: false,
  },
  coverage: {},
  contact: {},
  vehicleList: [],
  vehicleModelList: [],
  vehicleState1: {},
  formTransitionClass: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_HOME_STATE: {
      return { ...state, ...action.payload };
    }
    case ADD_PAGE_TO_SKIP_LIST: {
      return { ...state, skipPages: [...state.skipPages, action.payload] };
    }
    case REMOVE_PAGE_FROM_SKIP_LIST: {
      return { ...state, skipPages: state.skipPages.filter((page) => page !== action.payload) };
    }
    case UPDATE_NESTED_HOME_STATE: {
      if (action.payload.currentPageData.fields) {
        const keys = Object.keys(action.payload).find((key) => Object.keys(action.payload.currentPageData.fields).some((inputName) => action.payload.currentPageData.fields[inputName].key === key));
        return { ...state, [action.payload.getDeepPageKey]: { ...state[action.payload.getDeepPageKey], [keys]: action.payload[keys] } };
      }
      return { ...state, [action.payload.getDeepPageKey]: { ...state[action.payload.getDeepPageKey], [action.payload.currentPageData.pageKey]: action.payload[action.payload.currentPageData.pageKey] } };
    }
    default: {
      return state;
    }
  }
};
