let baseURL = () => 'https://api.uselenox.com';
const DEV_SERVER = process.env.REACT_APP_LENOX_DEV_SERVER || '';

if (DEV_SERVER) {
  baseURL = () => DEV_SERVER;
}

// eslint-disable-next-line import/prefer-default-export
export const getDataUrlPet = () => ({
  pet: `${baseURL()}/api/v2/external/pet.php`,
});
