export const FORCED_FORM = {
  form: localStorage.getItem('formNumber') || 9,
  variant: localStorage.getItem('variantNumber') || 6,
};

export const ALTERNATIVES_FORM = {
  9: {
    variants: {
      6: {
        english: 5,
        spanish: 8,
      },
      11: {
        english: 16,
      },
      12: {
        english: 16,
      },
      13: {
        english: 16,
      },
      14: {
        english: 16,
      },
    },
  },
  2000: {
    variants: {
      4: {
        english: 5,
      },
    },
  },
  3000: {
    variants: {
      1: {
        english: 2,
      },
      3: {
        english: 2,
      },
      7: {
        english: 2,
      },
      8: {
        english: 2,
      },
      9: {
        english: 2,
      },
    },
  },
  7000: {
    variants: {
      1: {
        english: 2,
      },
    },
  },
  8000: {
    variants: {
      1: {
        english: 2,
      },
      4: {
        english: 5,
      },
      6: {
        english: 2,
      },
      7: {
        english: 2,
      },
      10: {
        english: 2,
      },
      11: {
        english: 2,
      },
      12: {
        english: 2,
      },
      13: {
        spanish: 14,
      },
    },
  },
  11000: {
    variants: {
      1: {
        english: 2,
      },
    },
  },
  12000: {
    variants: {
      1: {
        english: 2,
      },
    },
  },
  14000: {
    variants: {
      1: {
        english: 2,
      },
    },
  },
  19000: {
    variants: {
      1: {
        english: 2,
      },
    },
  },
};

export const logo = '/6sm4Zf_g.png';
export const brand = 'OttoInsurance';
