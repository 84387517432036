// import { AutoSiteId, HomeSiteId, MedicareSiteId } from '../Utils/common';
import {
  AutoSiteId,
  HomeSiteId,
  LifeSiteID,
  MedicareSiteID,
  PetSiteID,
} from '../Utils/common';
import { FORCED_FORM } from '../AutoSavings/FORM_DEFAULTS';
// import { FORCED_FORM as MEDICARE_FORCED_FORM } from '../Medicare/FORM_DEFAULTS';
import { FORCED_FORM as HOME_FORCED_FORM } from '../HomeInsure/FORM_DEFAULTS';
import { FORCED_FORM as LIFE_FORCED_FORM } from '../Life/FORM_DEFAULTS';
// import { globalMedicareSiteTagForm, globalSiteTagForm } from '../Presells/PresellHelpers/pixelsCode';
import { getDataUrlAuto } from '../AutoSavings/Endpoints/Endpoints';
// import { getMedicareDataUrl } from '../Medicare/Endpoints/Endpoints';
import { getDataUrlHome } from '../HomeInsure/Endpoints/Endpoints';
import * as Domains from '../Constants/Domains';
import * as URL from '../Constants/URL_PATHS';
import { getDataUrlMedicare } from '../Medicare/Endpoints/Endpoints';

export default class DomainCheckHelper {
    static _siteIDs = {
      autoSavings: AutoSiteId,
      medicare: MedicareSiteID,
      homeInsure: HomeSiteId,
      life: LifeSiteID,
      pet: PetSiteID,
    };

    static _forcedForms = {
      autoSavings: FORCED_FORM,
      medicare: { form: '1000', variant: 1 },
      homeInsure: HOME_FORCED_FORM,
      life: LIFE_FORCED_FORM,
      pet: { form: '1000', variant: 1 },
    };
    //
    // static _siteTagScripts = {
    //   autoSavings: globalSiteTagForm,
    //   medicare: globalMedicareSiteTagForm,
    //   homeInsure: globalSiteTagForm,
    // };

    static _dataUrls = {
      autoSavings: getDataUrlAuto(),
      medicare: getDataUrlMedicare(),
      homeInsure: getDataUrlHome(),
      life: getDataUrlHome(),
    };

    static _pageTitles = {
      autoSavings: 'Auto Insurance Quotes',
      medicare: 'Medicare Insurance Quotes',
      homeInsure: 'Home Insurance Quotes',
      life: 'Life Insurance Quotes',
      pet: 'Pet Insurance Quotes',
    };

    static _defaultDomains = {
      autoSavings: 'Auto-Savings',
      medicare: 'Medicare SeniorSavings',
      homeInsure: 'Home Insure',
      life: 'Life Insurance',
      pet: 'Pet Insurance',
    };

    static findDomain = (str) => window.location.host.indexOf(str) >= 0 || window.location.pathname.indexOf(str) >= 0 ;

    static checkDomain = () => {
      if (DomainCheckHelper.findDomain(URL.MEDICARE)) {
        return Domains.MEDICARE;
      }
      if (DomainCheckHelper.findDomain(URL.HOME)) {
        return Domains.HOME;
      }
      if (DomainCheckHelper.findDomain(URL.LIFE) || DomainCheckHelper.findDomain(URL.SMARTLIFE)) {
        return Domains.LIFE;
      }
      if (DomainCheckHelper.findDomain(URL.PET)) {
        return Domains.PET;
      }
      return Domains.AUTO_SAVING;
    };

    // eslint-disable-next-line no-underscore-dangle
    static getSiteID = () => DomainCheckHelper._siteIDs[DomainCheckHelper.checkDomain()];

    // eslint-disable-next-line no-underscore-dangle
    static getDefaultVariantObj = () => DomainCheckHelper._forcedForms[DomainCheckHelper.checkDomain()];

    // static getGlobalSiteTagScript = () => {
    //   return DomainCheckHelper._siteTagScripts[DomainCheckHelper.checkDomain()];
    // };

    // eslint-disable-next-line no-underscore-dangle
    static getDataUrl = () => DomainCheckHelper._dataUrls[DomainCheckHelper.checkDomain()];

    // eslint-disable-next-line no-underscore-dangle
    static getPageTitle = () => DomainCheckHelper._pageTitles[DomainCheckHelper.checkDomain()];

    // eslint-disable-next-line no-underscore-dangle
    static getDefaultDomain = () => DomainCheckHelper._defaultDomains[DomainCheckHelper.checkDomain()];
}
