let baseURL = () => 'https://api.uselenox.com';
const DEV_SERVER = process.env.REACT_APP_LENOX_DEV_SERVER || '';

if (DEV_SERVER) {
  baseURL = () => DEV_SERVER;
}

export const getDataUrlMedicare = () => ({
  medicare: `${baseURL()}/api/v2/external/medicare.php`,
});
