import { NetWorkGetRequest, NetworkRequest, NetworkRequestNoParams } from '../Helpers/NetworkRequest';
import { LifeSiteID } from '../Utils/common';

export const emailValidation = async (email) => NetWorkGetRequest('emailValidation', { email });

export const phoneValidation = async (phoneNumber, uuid) => NetWorkGetRequest('phoneValidation', { phoneNumber, user_id: uuid });

export const nameValidation = async (firstName, lastName) => NetWorkGetRequest('nameValidation', { firstName, lastName });

export const trackStep = (params) => NetworkRequest('trackStep', params);

export const trackStepWithCallback = async (params, callbackFunc) => {
  const result = await NetworkRequest('trackStep', params);
  if (callbackFunc) {
    callbackFunc();
  }
  return result;
};

export const trackStepPresellRequest = (params) => NetworkRequest('trackPresellStep', params);

export const getInitialData = async (
  affiliateId,
  siteId,
  queryKeys,
  urlVariantOverride,
  form,
  variant,
  uuid = '',
  urlParams = {},
) => NetworkRequest('auto', {
  queryKeys,
  urlVariantOverride,
  form,
  variant,
  uuid,
  affiliate_id: affiliateId,
  site_id: siteId,
  mobilePage: window.screen.width < 600,
  zipSubmitForm: urlParams.zipSubmitForm ? urlParams.zipSubmitForm : undefined,
  zipSubmitVariant: urlParams.zipSubmitVariant ? urlParams.zipSubmitVariant : undefined,
}, 'get_initial_data');

export const getClickPayload = async (
  // eslint-disable-next-line camelcase
  user_id,
  // eslint-disable-next-line camelcase
) => NetworkRequestNoParams('links_payload', `user_id=${user_id}`);

export const getPixels = async (affiliateId, siteId, ts, userAgent, device) => NetWorkGetRequest('getPixels', {
  affiliateId,
  siteId,
  ts,
  device,
  user_agent: userAgent,
}, '');

export const getVehicleYearsData = async () => NetworkRequest('vehicles', {
}, 'getVehicleYears');

export const getVehicleMakesData = async () => NetworkRequest('vehicles', {
}, 'getVehicleMakes');

export const getVehiclesData = async (type, value) => NetWorkGetRequest('vehicles', {
  type,
  value,
}, 'getVehicles');

export const logPixels = async (affiliateId, siteId, step, userId, pixels, debug, response = '') => NetworkRequest('logPixels', {
  step,
  pixels,
  response,
  debug,
  affiliate_id: affiliateId,
  site_id: siteId,
  user_id: userId,
}, 'logPixelFire');

export const logClearcoverRevenue = async (params) => NetworkRequest('clearcover', params, 'logRevenue');

export const getCustomOffers = async (siteId, code, uuid = '', affid = '', state = '', form = '') => NetworkRequest('ty', {
  site_id: siteId,
  code,
  uuid,
  affid,
  state,
  form_id: form,
}, '');

export const getClicklisting = async (siteId, source, isListicle, session) => NetworkRequest('clicklisting', {
  site_id: siteId,
  source,
  isListicle,
  session,
}, '');

export const getPayout = async (affiliateId, siteId, userAgent) => NetworkRequest('getPayout', {
  site_id: siteId,
  affiliate_id: affiliateId,
  user_agent: userAgent,
}, '');

export const spendData = async (affiliateId, siteId, userId, userAgent, s1, s2, ip, step) => NetworkRequest('spendData', {
  lead_age: 0,
  affiliate_id: affiliateId,
  site_id: siteId,
  user_id: userId,
  user_agent: userAgent,
  sub_id: { s1, s2 },
  ip,
  step,
}, '');

export const sendTTCLID = async (siteId, uuid, ttclid) => NetworkRequest('ttclid', {
  site_id: siteId,
  uuid,
  ttclid,
}, '');

export const getZipFromLenox = async (zip, strict = false) => {
  let city;
  let state;
  let geoData;
  try {
    if (localStorage.getItem('geoData')) {
      geoData = JSON.parse(localStorage.getItem('geoData'));
    }
  } catch (e) {
    console.error(e);
  }
  if (geoData && geoData.state && geoData.city) {
    city = geoData.city;
    state = geoData.state;
  }
  const response = await NetworkRequest('rapidApi', {
    zipcode: zip,
    affid: localStorage.getItem('affidASR'),
    uuid: localStorage.getItem('uuidASR'),
    city,
    stateAbbreviation: state,
  });
  if (!response || !response.data || !response.data.city || response.data.block === true) {
    return {
      data: {
        city: strict ? false : city,
        stateAbbreviation: strict ? false : state,
      },
    };
  }
  return response;
};

export const getFormVariant = async (form, variant, uuid) => {
  const { data } = await NetworkRequest('auto', {
    form_id: form,
    variant_id: variant,
    user_id: uuid,
  }, 'get_transunion_variant');
  const result = JSON.parse(data);
  return typeof result === 'object' && result;
};

export const getPrefillData = async (params) => NetworkRequest('autoPrefill', params, '', 'auto');

export const zipValidation = (zip) => NetWorkGetRequest('auto', { zip }, 'zip_validation');

const sitesRequest = (params, action) => NetworkRequest('sites', params, action);

export const getTYText = (form) => NetworkRequest('tyText', form);

export const getCarriers = (site) => sitesRequest({ site }, 'getCarriers');

const getDomain = (debug, siteId) => {
  if (debug) {
    return 'home-insure.com';
  }
  if (siteId === LifeSiteID) {
    return `${window.location.hostname.replace('www.', '')}/life-insure`;
  }
  return window.location.hostname.replace('www.', '');
};

export const getDefaultSiteAssets = async (site, debug = false) => sitesRequest({
  location: 'landing',
  domain: getDomain(debug, site),
  site,
}, 'getDefaultAssets');

export const sendError = (message, siteID) => NetworkRequest('sendError', { message, site_id: siteID }, 'sendError', '');

export const errorPing = async (affiliateId, ip, international, denyListed, unsupportedState, userAgent) => NetworkRequest('errorPing', {
  affiliate_id: affiliateId,
  ip,
  international,
  denyListed,
  unsupportedState,
  user_agent: userAgent,
});

export const generateUUID = async () => NetworkRequest('generateUUID');
