import {
  ADD_PAGE_TO_SKIP_LIST,
  REMOVE_PAGE_FROM_SKIP_LIST,
  UPDATE_MEDICARE_STATE,
  UPDATE_NESTED_MEDICARE_STATE,
} from './Medicare.type';

const initialState = {
  currentPage: 0,
  skipPages: [],
  contact: {},
  formTransitionClass: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_MEDICARE_STATE: {
      return { ...state, ...action.payload };
    }
    case ADD_PAGE_TO_SKIP_LIST: {
      return { ...state, skipPages: [...state.skipPages, action.payload] };
    }
    case REMOVE_PAGE_FROM_SKIP_LIST: {
      return { ...state, skipPages: state.skipPages.filter((page) => page !== action.payload) };
    }
    case UPDATE_NESTED_MEDICARE_STATE: {
      if (action.payload.currentPageData.fields) {
        const keys = Object.keys(action.payload).find((key) => Object.keys(action.payload.currentPageData.fields).some((inputName) => action.payload.currentPageData.fields[inputName].key === key));
        return { ...state, [action.payload.getDeepPageKey]: { ...state[action.payload.getDeepPageKey], [keys]: action.payload[keys] } };
      }
      return { ...state, [action.payload.getDeepPageKey]: { ...state[action.payload.getDeepPageKey], [action.payload.currentPageData.pageKey]: action.payload[action.payload.currentPageData.pageKey] } };
    }
    default: {
      return state;
    }
  }
};
