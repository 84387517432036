import range from 'lodash/range';
import format from 'date-fns/format';
import add from 'date-fns/add';
import { getVehiclesData, getVehicleMakesData } from '../Requests/AppRequests';

export async function requestVehicleYears() {
  const currentMonth = format(new Date(), 'MM');
  const newYearmonths = ['09', '10', '11', '12'];
  const getNewYear = !newYearmonths.indexOf(currentMonth) >= 0;
  const currentYear = format(new Date(), 'yyyy');
  const addYear = add(new Date(), { years: 1 });
  const newYear = format(addYear, 'yyyy');
  const startYear = (getNewYear ? newYear : currentYear) - 26;
  const yearRange = range(getNewYear ? newYear : currentYear, startYear - 1);

  return yearRange.map((year) => ({ value: year, display: year }));
}

export async function requestMakes() {
  const { data } = await getVehicleMakesData();
  const makes = data.data;
  const makeOptions = makes.map((make) => ({ value: make, display: make }));
  return makeOptions;
}

export async function requestVehicleDataByYear(year) {
  const { data: { data, success } } = await getVehiclesData('year', year);
  if (!success) {
    return {};
  }
  const vehicleInfo = data.data;
  return vehicleInfo;
}

export async function requestVehicleDataByMake(make) {
  const { data: { data, success } } = await getVehiclesData('make', make);
  const vehicleInfo = {};
  if (success) {
    data.data.forEach((yearCollection) => {
      const yearKey = Object.keys(yearCollection)[0];
      vehicleInfo[yearKey] = yearCollection[yearKey];
    });
  }
  return vehicleInfo;
}

export function getMakes(year, vehiclesData) {
  if (!Object.keys(vehiclesData || {}).length || !year) {
    return [];
  }
  return Object.keys(vehiclesData)
    .map((currentMake) => ({ value: currentMake, display: currentMake }));
}

export function getYears(make, vehiclesData) {
  if (!Object.keys(vehiclesData).length || !make) {
    return [];
  }
  return Object.keys(vehiclesData)
    .map((currentYear) => ({ value: currentYear, display: currentYear }))
    .reverse();
}

export function getModelsByYear(year, vehiclesData) {
  if (!Object.keys(vehiclesData).length || !year) {
    return [];
  }
  return Object.keys(vehiclesData[year])
    .map((currentModel) => ({ value: currentModel, display: currentModel }));
}

export function getTrimsByYear(year, model, vehiclesData) {
  if (!Object.keys(vehiclesData).length || !year || !model) {
    return [];
  }
  const optionsAvailable = vehiclesData[year][model];
  return Object.keys(optionsAvailable)
    .map((key) => {
      const { trimDesc, vinPrefix } = optionsAvailable[key];
      return { value: `${trimDesc}_${vinPrefix}`, display: trimDesc };
    });
}

export function getModels(make, vehiclesData) {
  if (!Object.keys(vehiclesData || {}).length || !make) {
    return [];
  }
  return Object.keys(vehiclesData[make])
    .map((currentModel) => ({ value: currentModel, display: currentModel }));
}

export function getTrims(make, model, vehiclesData) {
  if (!Object.keys(vehiclesData || {}).length || !make || !model) {
    return [];
  }
  const optionsAvailable = vehiclesData[make][model];
  return Object.keys(optionsAvailable)
    .map((key) => {
      const { trimDesc, vinPrefix } = optionsAvailable[key];
      return { value: `${trimDesc}_${vinPrefix}`, display: trimDesc };
    });
}

export function getTrimWithoutVin(make, model, vehiclesData) {
  if (!Object.keys(vehiclesData || {}).length || !make || !model) {
    return [{ value: -1, display: 'No values available' }];
  }
  const optionsAvailable = vehiclesData[make][model];
  return Object.keys(optionsAvailable)
    .map((key) => {
      const { trimDesc, vinPrefix } = optionsAvailable[key];
      return { value: trimDesc, display: trimDesc, extraInfo: vinPrefix };
    });
}
